import React from "react";
import profileImg from '../../assets/profil.jpg';

class AboutContent extends React.Component {
     render() {
        return (
            <div className="about">
                <br/><br/><br/>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="text-center"> 		                                
                            <img src={profileImg} className="img-responsive profile"/>   
                            <br/><br/>
                            <div className="motto">
                                nu există artă
                            </div>                                        	                    	
                        </div>	
                    </div>                               
                </div>
            </div>
        );
    }
}

export default AboutContent;

