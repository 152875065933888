import React from "react";
import scenicImg from '../../assets/scenic-text.png';
import stihImg from '../../assets/stih.png';
import "lightgallery/dist/css/lightgallery.min.css";

import $ from 'jquery';

import 'lightgallery';
import 'lg-thumbnail';
import 'lg-fullscreen';

class HomeContent extends React.Component {

    onLightGallery = node => {
        this.lightGallery = node;
        $(node).lightGallery({
             pager: true
        });
    }

    componentWillUnmount() {
        $(this.lightGallery).data('lightGallery').destroy(true);
    }

    render() {
        return (
            <div className="home">
                <br/>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="ib-card"> 	
                            <a href="http://scenic.ro" target="_blank" className="text-center">                        
                                <img src={scenicImg} className="img-responsive"/>                        
                            </a>    
                            <div className="ib-card-footer">teatru</div>             
                        </div>	
                    </div>  
                    
                    <div className="col-sm-6">
                        <div className="ib-card"> 	
                            <a href="http://stih.ro" target="_blank" className="text-center">                        
                                <img src={stihImg} className="img-responsive"/> 	        	                                       
                            </a>        
                            <div className="ib-card-footer">poezie</div>           
                        </div>	           
                    </div>  
                </div>


                <div className="row">
                    <div className="col-sm-12">
                        <div className="ib-card"> 	
                            <div className="ib-card-footer">fotografie</div>   
                        </div>
                    </div>               
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div id="lightgallery" ref={this.onLightGallery} className="ib-galery-card row">
                        
                            <a href={require('../../assets/galerie/1.jpg')} className="col-sm-6 text-center hover03">         	 
                                <img src={require('../../assets/galerie/1.jpg')}  className="img-responsive img-galery" />              
                            </a>
                            
                            <a href={require('../../assets/galerie/2.jpg')} className="col-sm-6 text-center  hover03">
                                <img src={require('../../assets/galerie/2.jpg')} className="img-responsive img-galery" />              
                            </a>
                            
                            <a href={require('../../assets/galerie/3.jpg')} className="col-sm-6 text-center  hover03">
                                <img src={require('../../assets/galerie/3.jpg')} className="img-responsive img-galery" />              
                            </a>
                            
                            <a href={require('../../assets/galerie/9.jpg')} className="col-sm-6 text-center  hover03">
                                <img src={require('../../assets/galerie/9.jpg')} className="img-responsive img-galery" />              
                            </a>
                            
                            <a href={require('../../assets/galerie/11.jpg')} className="col-sm-6 text-center  hover03">
                                <img src={require('../../assets/galerie/11.jpg')} className="img-responsive img-galery" />              
                            </a>
                            
                            <a href={require('../../assets/galerie/12.jpg')} className="col-sm-6 text-center  hover03">
                                <img src={require('../../assets/galerie/12.jpg')} className="img-responsive img-galery" />              
                            </a>
                            
                        </div>
                         
                    </div>        
                </div>

                <br/><br/><br/><br/><br/>
            </div>
        );
    }
}

export default HomeContent;