import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

class Navbar extends React.Component {

	render() {

		return (
			// <!-- Navigation -->
			<header className="bg-dark">
				<nav className="navbar navbar-expand-md navbar-dark container">
					<a className="navbar-brand logo" href="home">iulian buzdugan</a>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

 					<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
     					<div className="navbar-nav">
     
      					</div>
       					<ul className="navbar-nav ml-auto" id="#mainNavbarNavDropdown">

						    <li className="nav-item">
								<a className="nav-link"  href="home"><FontAwesomeIcon icon={faHome} /><div>Acasa</div></a>
							</li>

							<li className="nav-item">
								<a className="nav-link" href="about"><FontAwesomeIcon icon={faUserCircle} /><div>Despre</div></a>
							</li>

						</ul>
					</div>

				</nav>
			</header>
		);

	}
}

export default Navbar;