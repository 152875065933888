import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'jquery';
import 'popper.js'; 
import 'bootstrap/dist/css/bootstrap.css';
import "lightgallery/dist/css/lightgallery.min.css";
import "lightgallery/dist/js/lightgallery.min.js";
import { BrowserRouter} from 'react-router-dom';

import 'bootstrap/dist/js/bootstrap.js';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render( <BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
