import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faGooglePlusSquare } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';

class Footer extends React.Component {
	render() {
		return (
			<footer className="footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-lg-offset-2 col-md-12 col-md-offset-1">
							
							<div className="text-center center-block">
								<br/>
								<a href="https://www.facebook.com/iulian.d.buzdugan" target="_blank">
									<FontAwesomeIcon icon={faFacebookSquare} className="fa-2x social" />
								</a>
								<a href="https://www.instagram.com/iulianbuzdugan/" target="_blank">
									<FontAwesomeIcon icon={faInstagram}  className="fa-2x social" />
								</a>
								<a href="https://plus.google.com/u/0/+IulianDanielBuzdugan" target="_blank">
									<FontAwesomeIcon icon={faGooglePlusSquare}  className="fa-2x social" />
								</a>
								<a href="mailto:buzdugan.iulian@gmail.com" target="_blank">
									<FontAwesomeIcon icon={faEnvelopeSquare}  className="fa-2x social" />
								</a>
							</div>	
							
							<p className="copyright">
								Copyright &copy; Iulian Buzdugan 2015-2019
							</p>
						</div>
					</div>
				</div>
			</footer>
		);
	}


}

export default Footer;