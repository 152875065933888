import React from 'react';
import './App.scss';

import Navbar from './common/navbar/Navbar'; 
import Footer from './common/footer/Footer';
import HomeContent from './page/HomeContent';
import AboutContent from './page/AboutContent';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <Navbar/>

      <main role="main" className="container">

        <Switch>
          <Route path="/" component={HomeContent} exact />
          <Route path="/home" component={HomeContent} />
          <Route path="/about" component={AboutContent} />
        </Switch>

      </main>

      <Footer/>

    </div>
  );
}

export default App;
